import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login"; // Correct path to your Login component
import FileUpload from "./components/FileUpload";
import PrivateRoute from "./components/PrivateRoute";
import "antd/dist/reset.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/upload" element={<PrivateRoute element={FileUpload} />} />
      </Routes>
    </Router>
  );
}

export default App;
