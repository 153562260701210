import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import '../style/Carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = () => {
    const slides = [
        {
            image: "https://i.ibb.co/XXV3LzW/a-modern-and-clean-illustration-with-a-w-71-Yh-STUTFShn-ZDk9-K1-1-A-JR-Zrswg-SAi2-Gl-Xx1d848-A.jpg",
            title: "Fast and Secure",
            points: [
                "Quickly upload files with high-speed.",
                "File upload is end to end encrypted"
            ]
        },
        {
            image: "https://i.ibb.co/3yWsn6S/a-clean-and-modern-illustration-of-a-clo-f-Zk-X02-SNR3-SS0-Dqkn-K-4-ZA-o2-QLXv-Ot-Qtq-PGt-SRWZu5-UQ.jpg",
            title: "Large File Support",
            points: [
                "Effortlessly upload files up to 10GB",
                "Seamless handling of large files"
            ]
        },
        {
            image: "https://i.ibb.co/6XwgZ3T/a-vector-illustration-of-a-cloud-storage-Y7-XJ0-Naz-TKKNf-go3f6-Twg-Pd-Bg-z-PR0i2-MNSRE50kzw.jpg",
            title: "User-Friendly Interface",
            points: [
                "Intuitive design for effortless file",
                "Admin gets emailed for each upload of yours"
            ]
        }
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    // Auto scroll effect
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prevSlide) => 
                prevSlide === slides.length - 1 ? 0 : prevSlide + 1
            );
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(timer); // Cleanup on unmount
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,      // Ensure arrows are visible
        autoplay: true,          // Enable autoplay
        autoplaySpeed: 2000,     // Set autoplay speed to 3 seconds
        pauseOnHover: true,      // Pause autoplay on hover
        appendDots: dots => (
            <div>
                <ul className="custom-dots"> {dots} </ul>
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className="carousel-container" style={{marginTop:"10%"}}>
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div key={index} className="carousel-slide">
                        <img
                            src={slide.image}
                            alt={`Slide ${index + 1}`}
                            className="slide-image"
                        />
                        <div className="slide-content">
                            <h2 className="slide-title">{slide.title}</h2>
                            <ul className="slide-points">
                                {slide.points.map((point, i) => (
                                    <li key={i}>{point}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Carousel;
